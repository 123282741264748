




import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import { Box, Button, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import Debug from "../components/Debug";
import useSiteMetadata from "../components/SiteMetadata";
import Markdown from "../components/Markdown";
import ButtonBackToMainPage from "../components/ButtonBackToMainPage";





const getDataBackup = ({email, ceo, website,address, name, nip, regon}) => (`
# Polityka prywatności

Z niniejszego dokumentu dowiesz się, w jaki sposób i na jakich zasadach przetwarzamy Twoje dane osobowe kiedy kontaktujesz się z nami lub korzystasz z naszych usług.

Dowiesz się, m.in.:

* na jakiej podstawie przetwarzamy Twoje dane,
* w jakim celu je przetwarzamy,
* czy masz obowiązek podać nam Twoje dane,
* jak długo przechowujemy Twoje dane,
* czy przekazujemy Twoje dane innym podmiotom,
* czy przetwarzamy Twoje dane poza Europejskim Obszarem Gospodarczym,
* czy przetwarzamy Twoje dane w sposób zautomatyzowany oraz czy je profilujemy.

Wszelkie wyrażenia pisane wielką literą mają znaczenie, jakie zostało im nadane w Regulaminie Strony internetowej dostępnej pod adresem ${website}/regulamin lub w Polityce Plików Cookies dostępnej pod adresem ${website}/polityka-plikow-cookies chyba, że co innego wynika z treści niniejszego dokumentu.


# Administrator danych osobowych

Administratorem Twoich danych osobowych jest ${ceo} prowadzący działalność gospodarczą o nazwie ${name} z adresem ${address}, wpisana do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, nr NIP ${nip}, nr REGON ${regon}. 

Kontakt z Administratorem możliwy jest za pomocą poczty elektronicznej na adres ${email} oraz w formie pisemnej na adres działalności gospodarczej podany wyżej.


# Skąd pozyskujemy Twoje dane osobowe?

Dane osobowe, które zbieramy za pośrednictwem naszej Strony internetowej działającej w domenie ${website}, pochodzą bezpośrednio od Ciebie. Pozyskanie Twoich danych odbywa się gdy:



* przeglądasz Stronę internetową oraz jej podstrony,
* zapisujesz się do naszego bezpłatnego newslettera,
* wysyłasz do nas pytania drogą e-mailową bądź za pomocą formularzy kontaktowych dostępnych na Stronie internetowej,
* odwiedzasz nasze konta na portalach społecznościowych – Instagram oraz Facebook oraz kontaktujesz się z nami za pomocą tych portali.

Dane osobowe, które przetwarzamy, możemy także zbierać za pośrednictwem źródeł publicznie dostępnych.

Wszelkie treści wprowadzane przez Użytkowników zgodnie z Regulaminem nie mogą zawierać danych osobowych umożliwiających identyfikację jakiejkolwiek osoby trzeciej, której dane dotyczą, w tym m.in., ale nie wyłącznie:



* imię lub imiona,
* nazwisko,
* numer PESEL, 
* numer NIP,
* dane adresowe, 
* nazwa lub NIP pracodawcy,
* nazwa prowadzonej działalności gospodarczej lub pracodawcy,
* numer telefonu,
* adres internetowej poczty e-mail,
* adres zamieszkania.

Strona internetowa nie służy wprowadzaniu danych osobowych osób trzecich. Wszelkie dane należy wprowadzać w formie zanonimizowanej, uniemożliwiającej identyfikację jakiejkolwiek osoby, której dane mogłyby dotyczyć.


# Kategorie danych osobowych, które przetwarzamy

W związku z naszą działalnością przetwarzamy następujące kategorie danych osobowych:

**Dane adresowe** – wyłącznie adres poczty internetowej e-mail – jest on niezbędny do celów kontaktowych pomiędzy Administratorem oraz Użytkownikiem. Jest on przechowywany w naszej bazie danych.

**Numer telefonu** -  jest on niezbędny do celów kontaktowych pomiędzy Administratorem oraz Użytkownikiem. Jest on przechowywany w naszej bazie danych.

**Adres IP** (skrót od angielskiego Internet Protocol Address) - to numer identyfikacyjny nadawany komputerom lub innym urządzeniom łączącym się z siecią, który zapewnia im prawidłową komunikację. Za każdym razem kiedy komputer Użytkownika łączy się z naszym serwerem, nasz serwer odczytuje adres IP powiązany z danym żądaniem. Dzięki temu nasz serwer jest w stanie rozpoznać, że poszczególne żądania pochodzą z jednego konkretnego urządzenia lub grupy urzadzeń. Informacja ta jest wykorzystywana przez nas w celach technicznych, umożliwiających prawidłowe funkcjonowanie Strony internetowej. Adres IP może być także wykorzystywany w celach statystycznych, takich jak zbieranie ogólnych informacji demograficznych, jak lokalizacja, z której następuje połączenie.

**Pliki cookies** – wykorzystujemy technologię plików cookies oraz podobne technologie w celu dostosowania Strony internetowej do Twoich indywidualnych potrzeb, umożliwienia podtrzymania sesji logowania oraz w celach statystycznych. Więcej informacji na temat stosowanych przez nas plików cookies znajdziesz w Polityce plików cookies dostępnej na Stronie internetowej.


# Cele przetwarzania danych osobowych, ich podstawa prawna oraz okres przetwarzania

Jako Administrator danych osobowych przetwarzamy Twoje dane osobowe:



* w celu świadczenia usług drogą elektroniczną w zakresie udostępniania Tobie Treści zawartych na Stronie internetowej, a także do prowadzenia z Tobą komunikacji oraz celem zapewnienia funkcjonalności i bezpieczeństwa użytkowania Strony internetowej – wówczas podstawą prawną przetwarzania jest niezbędność przetwarzania do wykonania umowy (art. 6 ust. 1 lit. b RODO),
* w celach analitycznych, statystycznych, marketingowych – podstawą prawną przetwarzania jest Twoja zgoda (art. 6 ust. 1 lit a RODO);
* w celach analitycznych, statystycznych, marketingowych – podstawą prawną przetwarzania jest nasz uzasadniony interes prawny, którym jest możliwość oferowania Tobie dostępu do bezpłatnych treści w zamian za Twoją akceptację korzystania przez nas z narzędzi analityczno-marketingowych (art. 6 ust. 1 lit. f RODO),
* w celach archiwalnych - w szczególności w celu weryfikacji czy dany Użytkownik o danym adresie e-mail korzystał już ze Strony internetowej lub złamał postanowienia Regulaminu - wówczas podstawą prawną przetwarzania jest nasz uzasadniony interes prawny (art. 6 ust. 1 lit. f RODO),
* w celu ewentualnego ustalenia i dochodzenia roszczeń lub obrony przed nimi oraz w celu zapewnienia bezpieczeństwa Twoich danych; realizacji tym celom służy także archiwizacja prowadzonej komunikacji – podstawą prawną przetwarzania jest nasz uzasadniony interes (art. 6 ust. 1 lit. f RODO) polegający na ochronie naszych praw;
* w celu weryfikacji uprawnień do wykonywania zawodu oraz zagwarantowania, że treści udostępniane są wyłącznie osobom wykonującym zawody medyczne – podstawą prawną przetwarzania jest nasz uzasadniony interes (art. 6 ust. 1 lit. f RODO).
* w celu realizacji obowiązków ustawowych ciążących na nas jako administratorze wynikających w szczególności z przepisów podatkowych i przepisów o rachunkowości – podstawą prawną przetwarzania jest realizacja naszego obowiązku prawnego (art. 6 ust. 1 lit. c RODO);
* w celu obsługi reklamacji - wówczas podstawą prawną przetwarzania jest nasz uzasadniony interes prawny (art. 6 ust. 1 lit f RODO);
* w celu przeprowadzenia niezbędnych czynności zmierzających do zawarcia umowy w zakresie przedstawienia oferty i komunikacji zmierzającej do zawarcia umowy - wówczas podstawą prawną przetwarzania jest niezbędność przetwarzania do wykonania umowy (art. 6 ust. 1 lit. b RODO).

Twoje dane osobowe przetwarzamy przez czas niezbędny do osiągnięcia powyższych celów przetwarzania danych osobowych chyba że wcześniej wycofasz zgodę na przetwarzanie Twoich danych. Dane osobowe mogą być przetwarzane przez okres dłuższy niż to zostało powyżej wskazane w przypadku gdy wynika to ze szczególnych przepisów prawa, w wyniku których na Administratora zostaje nałożony taki obowiązek lub które nadają mu takie uprawnienie.


# Charakter podania danych

Z momentem połączenia się przez Ciebie z naszą Stroną internetową, gromadzone są Twoje dane osobowe (w tym adres IP) oraz wykorzystywane są niezbędne pliki cookies lub inne podobne technologie. Przetwarzanie tych danych jest niezbędne do funkcjonowania Strony internetowej.

Możesz przeczytać więcej o tym, jak używamy plików cookie i jak zmienić preferencje dotyczące plików cookie na naszej stronie Polityka plików cookies.

Nasza strona internetowa korzysta z Google reCAPTCHA. W celu ochrony formularzy wejściowych na naszej stronie używamy „reCAPTCHA” firmy Google LLC z siedzibą w USA lub Google Ireland Limited z siedzibą w Irlandii , zwanej dalej „Google”.

Korzystając z tej usługi, można wykorzystać analizę zachowania podczas wizyty na stronie internetowej w celu rozróżnienia, czy odpowiednie dane wejściowe są pochodzenia ludzkiego, czy też zostały nieprawidłowo wykonane w wyniku zautomatyzowanego przetwarzania maszynowego. Na potrzeby tej analizy serwis ocenia różne informacje, np. adres IP, długość pobytu na stronie lub śledzone ruchy myszy, i przekazuje te dane do Google.

Adres IP przesłany w ramach „reCAPTCHA” nie zostanie połączony z innymi danymi Google, chyba że jesteś zalogowany na swoim koncie Google w momencie korzystania z wtyczki „reCAPTCHA”. Jeśli chcesz zapobiec przekazywaniu i przechowywaniu danych o Tobie i Twoim zachowaniu na naszej stronie internetowej przez „Google”, musisz wylogować się z „Google” przed odwiedzeniem naszej strony lub skorzystaniem z wtyczki reCAPTCHA.

Informacje uzyskane za pomocą usługi „reCAPTCHA” są zgodne z Warunkami użytkowania Google: [https://www.google.com/intl/pl/policies/privacy](https://www.google.com/intl/pl/policies/privacy) .

Jako osoba, której dane dotyczą, masz możliwość wyboru, czy chcesz korzystać z naszych usług świadczonych drogą elektroniczną. Jeżeli chcesz z nich korzystać, to Ty podejmujesz decyzję jakie dane osobowe i inne informacje zostaną przez Ciebie udostępnione


# Odbiorcy danych osobowych

Twoje dane osobowe mogą być powierzane do przetwarzania podmiotom, które przetwarzają dane na rzecz naszej firmy. Nasi zaufani podwykonawcy, z których usług korzystamy to: 



* dostawca usług IT w zakresie hostingu danych oraz usług poczty służbowej e-mail – powierzamy im dane w celu ich przechowywania na udostępnionej nam przestrzeni serwera, w tym serwera skrzynki pocztowej, a także w celach archiwizacji tych danych,
* Google LLC z siedzibą w USA lub Google Ireland Limited z siedzibą w Irlandii – w celu korzystania z usług Google, 
* SendPulse Inc., 220 E 23rd St #401, New York, NY 10010 – celem świadczenia usługi mailingowej, korzystamy z niej np. wysyłając do Ciebie e-mail z linkiem aktywującym konto,
* osoby, które przy świadczeniu usług związanych ze wsparciem technicznym naszej Strony internetowej mogą potencjalnie uzyskać dostęp do Twoich danych osobowych, 
* inni nasi zaufani podwykonawcy, którzy świadczą usługi niezbędne do bieżącego funkcjonowania naszej Strony internetowej, którzy mogą brać udział w świadczeniu na Twoją rzecz usług prawnych oraz którzy mogą odpowiadać na Twoje wiadomości e-mail.

W sytuacji, w której powierzamy innym podmiotom Twoje dane osobowe zawieramy z nim umowę powierzenia przetwarzania danych osobowych. Podmiot przetwarzający przetwarza powierzone dane osobowe wyłącznie na potrzeby, w zakresie i w celach wskazanych w umowie powierzenia, o której mowa w zdaniu poprzedzającym. Bez powierzenia Twoich danych osobowych do przetwarzania zewnętrznym podmiotom nie moglibyśmy prowadzić swojej działalności i świadczyć na Twoją rzecz usług.

Twoich danych osobowych nie udostępniamy podmiotom trzecim bez Twojej wyraźnej zgody. Wyrażając zgodę na nasz Regulamin oraz Politykę prywatności wyrażasz zgodę na udostępnienie Twoich danych osobowych naszym podwykonawcom opisanym w tym rozdziale.

Twoje dane osobowe mogą być udostępniane bez Twojej zgody tylko i wyłącznie podmiotom prawa publicznego, a zatem organom władzy i organom administracji.


# Przekazywanie informacji do państw trzecich i organizacji międzynarodowych

Nasza Strona internetowa korzysta z usług Google, a jako Administrator nie mamy wpływu na to, w jaki sposób Google przetwarza Twoje dane osobowe. Dostawca ten stosuje standardowe klauzule umowne, zgodnie z art. 46 RODO, jego europejska siedziba oraz serwery są zlokalizowane na terenie EOG oraz deklaruje nie przekazywanie danych użytkowników europejskich m.in. do Stanów Zjednoczonych. W związku z powyższym Google deklaruje zapewnienie adekwatnego poziomu ochrony danych osobowych wymagany przez przepisy europejskie.  

Więcej informacji znajduje się pod adresem [https://cloud.google.com/privacy/gdpr](https://cloud.google.com/privacy/gdpr) 

W celu rozsyłania naszego newslettera oraz celem przesyłania do Ciebie wiadomości e-mail będziemy korzystali z narzędzia SendPulse firmy SendPulse Inc., 220 E 23rd St #401, New York, NY 10010.  Jego europejska siedziba oraz serwery są zlokalizowane na terenie EOG, niemniej jednak SendPulse Inc. może przetwarzać Twoje dane osobowe poza Europejskim Obszarem Gospodarczym. Będzie to miało miejsce na podstawie tzw. standardowych klauzul umownych, postanowień umowy zawartej między nami a SendPulse Inc., które gwarantują adekwatny stopień bezpiecznego przetwarzania Twoich danych osobowych.

Jeżeli interesują Cię szczegóły związane z przetwarzaniem danych w ramach narzędzia SendPulse, zachęcamy Ciebie do zapoznania się z wyjaśnieniami przygotowanymi przez firmę SendPulse Inc. pod adresem [https://sendpulse.com/legal/processing](https://sendpulse.com/legal/processing) 

Z wyjątkiem powyższych sytuacji, Twoje dane osobowe nie są przekazywane do państwa trzeciego poza Europejski Obszar Gospodarczy lub do organizacji międzynarodowej w rozumieniu RODO. W przypadku, gdyby dane osobowe miały zostać przekazywane do państwa trzeciego zostaniesz o tym uprzednio poinformowany, a my,  jako Administrator danych, będziemy stosować zabezpieczenia, o których mowa w Rozdziale V RODO.


# Prawa Użytkownika

Przysługujące Ci prawa związane z przetwarzaniem Twoich danych osobowych to:

**Prawo do bycia informowanym o przetwarzaniu danych osobowych.**

Jako Administrator danych jesteśmy zobowiązani do tego, aby przekazać Ci określone w RODO informacje, co wykonujemy niniejszym dokumentem Polityki prywatności.

**Prawo dostępu do danych**

Masz prawo do uzyskania informacji odnośnie przechowywanych przez nas danych osobowych na Twój temat. Składając wniosek w zakresie dostępu do swoich danych, otrzymasz informacje o przetwarzaniu danych osobowych, w tym przede wszystkim o celach i podstawach prawnych przetwarzania, zakresie posiadanych danych, podmiotach, którym dane osobowe są ujawniane i planowanym terminie ich usunięcia.

**Prawo do sprostowania**

Masz prawo do niezwłocznego sprostowania, poprawiania, uzupełniania, uaktualniania danych osobowych przechowywanych na Twój temat. Naszym obowiązkiem jest dbanie o to, aby nasza wzajemna komunikacja była oparta o dane prawdziwe, kompletne i aktualne. W każdym czasie możesz żądać, abyśmy dokonali zmiany danych Ciebie dotyczących.

**Prawo do bycia zapomnianym**

Masz prawo żądać usunięcia danych Ciebie dotyczących, które posiadamy jako Administrator danych osobowych. Możesz również wystąpić do nas o poinformowanie innych administratorów danych, którym przekazaliśmy Twoje dane, o tym, że są zobowiązani do ich usunięcia, 

Prawo do bycia zapomnianym nie ma zastosowania, gdy przetwarzanie jest niezbędne:



* do korzystania z prawa do wolności wypowiedzi i wolności dostępu do informacji,
* do wywiązania się przez nas z obowiązku prawnego, np. obowiązku podatkowego,
* ze względu na interesy zdrowia publicznego,
* do celów badań naukowych lub historycznych oraz do celów statystycznych,
* do ustalenia, dochodzenia i obrony roszczeń,
* z powodu interesu publicznego,
* w celu dochodzenia praw na mocy przepisów.

**Prawo do informacji**

Jeżeli zgłosiłeś prawo do sprostowania, do usunięcia lub ograniczenia przetwarzania danych, powiadomimy wszystkich odbiorców Twoich danych osobowych o sposobie sprostowania, usunięcia lub nałożenia ograniczeń w przetwarzaniu tych danych, chyba że jest to niemożliwe do przeprowadzenia lub wiąże się z nieproporcjonalnym wysiłkiem.

**Prawo do ograniczenia przetwarzania**

Możesz wystosować żądanie ograniczenia przetwarzania Twoich danych osobowych, w wyniku którego będziemy mogli wyłącznie przechowywać Twoje dane, ale nie będziemy mogli wprowadzać do nich zmian, w sytuacjach kiedy: 



* uważasz, że Twoje dane są przez nas przetwarzane bez podstawy prawnej, ale jednocześnie nie zgadzasz się na usunięcie swoich danych,
* dane są Ci potrzebne w celu ustalenia, dochodzenia lub obrony przed roszczeniami,
* złożyłeś sprzeciw wobec przetwarzania danych osobowych,  
* kwestionujesz prawidłowość Twoich  danych osobowych.

**Prawo do przenoszenia danych**

Masz prawo do uzyskania kopii przekazanych nam danych, które zostaną przesłane Tobie lub osobie trzeciej w ustrukturyzowanym, standardowym formacie, czytelnym dla czytników maszynowych. Jeżeli domagasz się przesłania tych danych do innego administratora danych, zostanie to zrealizowane, o ile istnieje taka możliwość techniczna. Niniejsze uprawnienie odnosi się wyłącznie do sytuacji, gdy przetwarzamy Twoje dane na podstawie Twojej zgody lub w związku z realizacją umowy.

**Prawo wniesienia sprzeciwu od przetwarzania danych osobowych**

W każdej chwili możesz wnieść sprzeciw, jeżeli nie zgadzasz się na przetwarzanie przez nas Twoich danych osobowych, które dotychczas przetwarzaliśmy w uzasadnionych celach zgodnych z przepisami prawa; w takiej sytuacji nie będziemy mogli już przetwarzać takich danych, chyba że wykażemy istnienie uzasadnionej, nadrzędnej wobec Twoich praw i wolności podstawy przetwarzania danych, bądź podstawy ustalenia, dochodzenia czy też obrony roszczeń. 

**Prawo do wycofania zgody**

Jeśli Twoje dane są przetwarzane na podstawie zgody, masz prawo ją wycofać w dowolnym momencie, ze skutkiem przyszłym. Pozostanie to bez wpływu na legalność dotychczas przetwarzanych danych.

**Prawo do niepodlegania profilowaniu**

Podczas korzystania z naszej Strony internetowej nie będziesz podlegał zautomatyzowanemu podejmowaniu decyzji, w tym profilowaniu w rozumieniu RODO, chyba że wyrazisz na to wyraźną zgodę. Jeżeli pojawią się sytuację, w których profilowanie będzie mogło mieć miejsce, to zawsze Cię o tym poinformujemy.

**Prawo do wniesienia skargi do organu nadzorczego**

Jeżeli uznasz, że przetwarzamy Twoje dane osobowe niezgodnie z prawem lub w jakikolwiek inny sposób naruszamy uprawnienia czy też obowiązki wynikające z powszechnie obowiązujących przepisów prawa, które regulują problematykę ochrony danych osobowych, masz prawo do wniesienia skargi do organu nadzorczego – Prezesa Urzędu Ochrony Danych Osobowych w Warszawie (ul. Stawki 2). Zanim skorzystasz z prawa do złożenia skargi, zachęcamy Ciebie do zwrócenia się w pierwszej kolejności do nas, abyśmy mogli wyjaśnić Tobie wszelkie wątpliwości.

Jeżeli chcesz skorzystać ze swoich powyższych praw, prześlij do nas wiadomość na adres e-mail podany na początku tego dokumentu.

Odpowiedź na zgłoszenie zostanie udzielona w ciągu miesiąca od jego otrzymania. W razie konieczności przedłużenia tego terminu, poinformujemy Cię o przyczynach takiego przedłużenia. Odpowiedź na Twój wniosek będzie udzielana na adres e-mail, z którego prześlesz wniosek, a w przypadku wniosków skierowanych listownie, listem zwykłym na adres przez Ciebie wskazany, o ile z treści listu nie będzie wynikała chęć otrzymania informacji zwrotnej na adres e-mail (w takim przypadku należy podać adres e-mail).

**Linki do zewnętrznych stron internetowych**

Tam, gdzie podajemy linki do stron internetowych innych niż my podmiotów, niniejsza Polityka Prywatności nie znajduje zastosowania i nie opisuje, w jaki sposób ten podmiot przetwarza Twoje dane osobowe. Zachęcamy Ciebie do zapoznania się z właściwymi politykami prywatności tych podmiotów znajdującymi się na ich witrynach internetowych.


# Plikie cookies

W ramach naszej Strony internetowej stosujemy pliki cookies, opisane szerzej w naszej Polityce plików cookies dostępnej na Stronie internetowej. Część z plików cookies jest niezbędna dla poprawnego działania serwisu, służąc zapewnieniu funkcjonalności, bezpieczeństwa i dostępności. Dzięki tym plikom wiemy, że zaakceptowałeś naszą Politykę Prywatności i Politykę plików cookies. Te pliki wykorzystujemy na podstawie naszego uzasadnionego interesu prawnego.


# Zautomatyzowane podejmowanie decyzji i profilowanie

Profilowanie to przetwarzanie danych osobowych celem  oceny niektórych cech osoby, której dane dotyczą. Profilowanie może mieć na celu analizę lub prognozę aspektów dotyczących efektów Twojej pracy zawodowej, sytuacji ekonomicznej, zdrowia, osobistych preferencji, zainteresowań, wiarygodności, zachowania, lokalizacji lub przemieszczania się.

Twoje dane osobowe nie podlegają procesom zautomatyzowanego przetwarzania, w tym profilowania, które wywołuje wobec Ciebie jakiekolwiek skutki prawne lub w podobny sposób istotnie na Ciebie wpływa.

Informujemy, że korzystamy z plików cookies oraz innych systemów rejestrujących ruch na naszej Stronie internetowej m.in. w celu umożliwienia ulepszenia jej funkcjonalności oraz jej optymalizacji.

Pliki cookies oraz inne narzędzia mogą sprawić, że będziemy podejmować względem Ciebie określone działania w zależności od informacji o Tobie jakie zgromadzimy dzięki mechanizmom śledzącym, jednakże działania te nie różnicują Twojej sytuacji jako naszego klienta. Informacje, o których mowa w tym punkcie to informacje o: 



* systemie operacyjnym i przeglądarce internetowej, z której korzystasz, 
* przeglądanych podstronach naszej strony internetowej oraz tego, w jaki sposób korzystasz ze Strony internetowej, 
* czasie spędzonym na danej podstronie, 
* przejściach pomiędzy poszczególnymi podstronami, 
* źródłach, z których przechodzisz do Strony internetowej.

Nie będziemy podejmować wobec Ciebie decyzji opierających się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu, które wywoływałyby wobec Ciebie skutki prawne lub w podobny sposób istotnie na Ciebie wpływały.


# Logi serwera

Informacje o niektórych zachowaniach Użytkowników podlegają logowaniu, czyli zapisaniu w bazie danych informacji o zaistnieniu wcześniej zdefiniowanej sytuacji w warstwie serwerowej. Dane te są wykorzystywane wyłącznie w celu administrowania naszą Stroną internetową oraz poprawienia jej funkcjonalności po stronie serwera.

Ponadto zapisowi mogą podlegać: 



* czas nadejścia zapytania do serwera,
* czas wysłania odpowiedzi przez serwer, 
* adres URL strony poprzednio odwiedzanej przez użytkownika (referer link) – w przypadku, gdy przejście do Strony internetowej przez zewnętrzny odnośnik – nigdy nie powiązujemy tej informacji z Użytkownikiem, 
* informacje o błędach jakie nastąpiły przy realizacji transakcji HTTPS – jest to jedyny log serwera, który może zawierać indywidualny numer zarejestrowanego Użytkownika, celem analizy, czy wspomniany błąd nie uszkodził danych powiązanych z Kontem Użytkownika bądź aby powiązać zapytanie do serwera zawierające szkodliwe oprogramowanie (malware) z zalogowanym Użytkownikiem,
* informacje o przeglądarce użytkownika,
* informacje o adresie IP użytkownika.


# Sposoby ochrony prywatności Użytkowników

Projektujemy naszą Stroną internetową z myślą o bezpieczeństwie Twoich danych i prywatności.

Dokładamy wszelkich starań, aby utrzymać fizyczne, elektroniczne i proceduralne zabezpieczenia w związku z gromadzeniem, przechowywaniem i ujawnianiem osobowych informacji klientów.

Ważne jest, abyś chronił się przed nieautoryzowanym dostępem do swojego komputera, urządzeń i aplikacji.


# Tryb zmiany polityki prywatności

Polityka Prywatności podlega okresowej weryfikacji i aktualizacji, w razie uzasadnionej potrzeby. Obecnie obowiązuje pierwsza wersja Polityki prywatności, opublikowana dnia 11 września 2024.


# Uwagi końcowe

Zgodnie z zasadą minimalizacji dbamy o to, aby Twoje dane osobowe były adekwatne, stosowne oraz ograniczone i niezbędne do osiągnięcia celów, w których są przetwarzane.

Jeżeli masz jakieś pytania lub chciałbyś złożyć wniosek czy skargę dotyczące przetwarzania danych osobowych przez nas jako Administratora danych, to wyślij do nas wiadomość e-mail na adres: ${email} lub skontaktuj się z nami drogą pocztową, wysyłając odpowiednią informację na adres Administratora danych osobowych, podany w tym niniejszym dokumencie. 

Pamiętaj, aby w treści takiego zgłoszenia wyraźnie wskazać: 



* dane osoby lub osób, których dotyczy Twoje zgłoszenie, 
* zdarzenie będące powodem zgłoszenia, 
* swoje żądania,
* podstawę prawną swoich żądań, 
* oczekiwany sposób odpowiedzi na Twoje zgłoszenie.

Każdy stwierdzony przypadek naruszenia bezpieczeństwa danych osobowych jest przez nas odpowiednio dokumentowany. W przypadku, kiedy zaistniałaby jedna z sytuacji określonych w przepisach RODO lub Ustawy o ochronie danych osobowych, o takim naruszeniu zostaną poinformowane osoby, których dane dotyczą, a jeżeli będzie miało to zastosowanie także Prezes Urzędu Ochrony Danych Osobowych.

W kwestiach, które nie zostały uregulowane niniejszą Polityką Prywatności, odpowiednie zastosowanie mają właściwe przepisy prawa powszechnie obowiązującego w Rzeczypospolitej polskiej, w tym w szczególności: przepisy RODO, Ustawy o ochronie danych osobowych i inne właściwe. W przypadku niezgodności postanowień niniejszej Polityki prywatności z powyższymi przepisami, pierwszeństwo mają te przepisy.
`)



const getData = ({email, companyFullName,serviceName,companyShortestName,  ceo, website, address, name, nip, regon}) => (`
 
# POLITYKA PRYWATNOŚCI DANYCH OSOBOWYCH I PLIKÓW COOKIES SERWISU  ${serviceName}

**Obowiązuje od: 20.10.2024 r.**

## § 1. Tożsamość administratora danych

1. Administratorem danych osobowych podawanych w trakcie korzystania z Serwisu prowadzonego pod nazwą ${serviceName} jest spółka ${companyFullName}. Dane kontaktowe Administratora: ${address} 

2. Dane przetwarzane są zgodnie z aktualnie obowiązującymi przepisami prawa; tj. Rozporządzeniem Parlamentu Europejskiego i Rady UE 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: RODO), ustawą z dnia 10 maja 2018 r. o ochronie danych, a także ustawą z 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną.

## § 2. Stosowane definicje

1. W niniejszej polityce stosuje się następujące definicje:
    * **Serwis** - serwis internetowy dostępny pod adresem ${website} za pośrednictwem którego Użytkownik może: przeglądać jego zawartość i kontaktować się z administratorem danych (formularz kontaktowy).
    * **Administrator danych osobowych** - podmiot, który decyduje o celu i środkach przetwarzania danych, w niniejszej polityce rozumie się przez to: ${companyFullName}.
    * **Użytkownik** - osoba fizyczna, której dane dotyczą i która korzysta z usług dostępnych w Serwisie.
    * **Dane osobowe** - wszelkie informacje, które bez nadmiernego czasu i kosztu mogą doprowadzić do identyfikacji osoby fizycznej, w tym jej dane identyfikacyjne, adresowe i kontaktowe.

## § 3. Cele przetwarzania danych

1. Administrator danych osobowych przetwarza dane osobowe w następujących celach:
    1. udzielenia odpowiedzi na zadane pytanie poprzez formularz kontaktowy lub za pośrednictwem danych teleadresowych dostępnych na stronie Serwisu (art. 6 ust. 1 lit. f RODO)
    2. jeżeli przekazujesz nam informacje o stanie zdrowia Twoim lub Twoich bliskich, przetwarzanie danych odbywa się w celu dopasowania usług świadczonych przez spółkę SENIOR HARMONY sp. z o.o., tak aby jak najlepiej spełnić Twoje oczekiwania i potrzeby (art. 6 list a i f oraz art. 9 ust. 2 lit a RODO),
    3. dochodzenia praw i roszczeń przez Administratora danych lub osobę, której dane dotyczą (art. 6 ust. 1 lit. f RODO)
2. **Podanie danych jest dobrowolne, ale niezbędne do udzielenia odpowiedzi na pytania zadane poprzez formularz kontaktowy, ewentualnego do dochodzenia jakichkolwiek roszczeń.**


## § 4. Sposoby pozyskiwania danych



1. Twoje dane osobowe w większości przypadków przetwarzamy, ponieważ sam nam je przekazujesz poprzez: 
    * wypełnienie formularza z danymi kontaktowymi podczas składania zapytania przez formularz na stronie,
    * bezpośredni kontakt z Administratorem danych za pomocą danych teleadresowych dostępnych na stronie lub w formie tradycyjnej w miejscu prowadzenia działalności.


## § 5. Zakres przetwarzanych danych

1. Zakres przetwarzanych danych osobowych został ograniczony do minimum niezbędnego do świadczenia usług w zakresie. W zależności od celu, możemy przetwarzać następujące informacje na Twój temat: 
    * imię i nazwisko, 
    * adres zamieszkania, 
    * adres wykonywania działalności gospodarczej, 
    * numer NIP, 
    * adres e-mail, 
    * numer telefonu, 
    * dane zawarte w korespondencji e-mail, 
    * adres IP.

2. Jeżeli dołączysz do wiadomości w formularzu kontaktowym lub e-mail lub też przekażesz w rozmowie telefonicznej jakiekolwiek informacje o stanie zdrowia Twoim lub Twoich bliskich – w formie opisu lub załączników do tej wiadomości – dane te będą przetwarzane w zakresie niezbędnym do dopasowania usług świadczonych przez spółkę ${companyShortestName}, tak aby jak najlepiej spełnić Twoje oczekiwania i potrzeby.


## § 6. Okres przetwarzania danych



1. Dane osobowe są przetwarzane przez okres niezbędny do realizacji celu, dla którego zostały zebrane, tj.: 
    * przez czas niezbędny do zakończenia komunikacji rozpoczętej przez Ciebie za pośrednictwem formularza kontaktowego, naszego adresu e-mail lub drogą telefoniczną.  Po tym okresie dane mogą zostać usunięte, ale mogą również podlegać archiwizacji, jeżeli przebieg komunikacji uzasadnia takową archiwizację, np. z uwagi na potrzebę wykazania tego przebiegu w przyszłości. W tym zakresie nie jesteśmy w stanie określić jednoznacznego terminu usunięcia danych.
    * do czasu odwołania zgody, jeśli przetwarzanie danych opiera się na zgodzie osoby, której dane dotyczą.


## § 7. Odbiorcy danych



1. Dane osobowe Użytkownika mogą zostać powierzone innym podmiotom w celu wykonania usług na zlecenie Administratora danych, w szczególności podmiotom w zakresie: 
    * hostingu strony www, 
    * serwisu i utrzymania systemów informatycznych, w których dane są przetwarzane,
    * prowadzenia obsługi księgowej i prawnej,
    * prowadzenia obsługi biurowej.
2. Twoje dane osobowe nie są przekazywane do państw trzecich, ani organizacji międzynarodowych. 
3. Dane osobowe przechowywane są na serwerach zlokalizowanych na terenie Europejskiego Obszaru Gospodarczego i nie dochodzi w tym zakresie do ich przekazywania do żadnego państwa trzeciego ani organizacji międzynarodowej. Jednak, jeżeli serwery wykorzystywanej przez Twoją pocztę e-mail zlokalizowane są poza Europejskim Obszarem Gospodarczym, zarówno wysyłane, jak i odbierane przez Ciebie wiadomości, przechowywane są na tymże serwerze. W związku z tym, dobrze jest upewnić się czy Twoja poczta e-mail korzysta z serwerów zlokalizowanych w obrębie Europejskiego Obszaru Gospodarczego.


## § 8. Prawa osób, których dane dotyczą



1. Osobie, której dane dotyczą przysługuje:
    * prawo dostępu do treści danych i ich sprostowania,
    * prawo do usunięcia danych, o ile nie obowiązują inne przepisy prawa, które zobowiązują administratora danych do archiwizacji danych przez określony czas, 
    * prawo do przenoszenia danych, o ile podstawą przetwarzania danych jest umowa lub zgoda osoby, której dane dotyczą, a przetwarzanie danych odbywa się w sposób automatyczny,
    * prawo do wniesienia sprzeciwu na przetwarzanie danych w celach marketingu bezpośredniego, realizowanego przez administratora danych w ramach usprawiedliwionego interesu prawnego, a także ograniczenia przetwarzania,
    * prawo do niepodlegania automatycznemu profilowaniu, jeśli administrator danych podejmowałby decyzje opierające się wyłącznie na automatycznym profilowaniu i niosące ze sobą skutki prawne dla osoby, której dane dotyczą lub podobnie na nią wpływały,
    * prawo do kontroli przetwarzania danych i informacji na temat tego, kto jest administratorem danych, a także uzyskania informacji o celu, zakresie i sposobie przetwarzania danych, treści tych danych, źródle danych, a także sposobie udostępniania, w tym o odbiorcach lub kategoriach odbiorców danych,
    * prawo do cofnięcia zgody w dowolnym momencie, jeśli podstawą przetwarzania danych była zgoda osoby, której dane dotyczą. Odwołanie zgody nie wpływa na zgodność z prawem przetwarzania dokonanego na jej podstawie, przed jej cofnięciem,
    * prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych (PUODO), jeśli osoba uzna, że przetwarzanie jej danych jest niezgodne z aktualnie obowiązującymi przepisami w tym zakresie.
2. Zasady związane z realizacją wskazanych powyżej uprawnień zostały opisane szczegółowo w art. 16 – 21 RODO. Zachęcamy do zapoznania się z tymi przepisami. Wskazane powyżej uprawnienia nie są bezwzględne i nie będą przysługiwać Ci w stosunku do wszystkich czynności przetwarzania Twoich danych osobowych. Zawsze jednak przysługuje Ci skarga do PUODO oraz możesz zwrócić się do nas z żądaniem udostępnienia Ci informacji o tym, jakie dane na Twój temat posiadamy oraz w jakich celach je przetwarzamy. W tym celu wyślij wiadomość na adres: ${address}


## § 9. Bezpieczeństwo danych osobowych

Administrator dba o bezpieczeństwo Twoich danych osobowych. Na bieżąco analizuje ryzyka, jakie wiążą się z poszczególnymi procesami przetwarzania Twoich danych, a następnie wdraża odpowiednie środki bezpieczeństwa i ochrony danych osobowych. Monitoruje stan infrastruktury technicznej, szkoli personel, aktualizuje stosowne procedury, wprowadza konieczne usprawnienia. W razie jakichkolwiek pytań dotyczących Twoich danych osobowych możesz skontaktować się pod adresem: ${address}. Prosimy także byś upewnił się czy regulamin poczty e-mail, z której korzystasz, nie uprawnia dostawcy poczty do skanowania treści wysyłanych i odbieranych wiadomości, w szczególności w celach marketingowych, co występuje najczęściej w przypadku nieodpłatnych usług poczty e-mail. W takiej sytuacji, poufność wymienianych wiadomości jest zagrożona, co nie zależy jednak od Administratora Twoich danych.


## § 10. Pliki cookies



1. Strona internetowa ${website}, podobnie jak niemal wszystkie inne strony internetowe, wykorzystuje pliki cookies.
2. Cookies to niewielkie informacje tekstowe, przechowywane na Twoim urządzeniu końcowym (np. komputerze, tablecie, smartfonie), które mogą być odczytywane przez mój system teleinformatyczny (cookies własne) lub systemy teleinformatyczne podmiotów trzecich (cookies podmiotów trzecich). W plikach cookies mogą być zapisywane i przechowywane określone informacje, do których następnie systemy teleinformatyczne mogą uzyskiwać dostęp w określonych celach.
3. Niektóre używane przez nas cookies są usuwane po zakończeniu sesji przeglądarki internetowej, tzn. po jej zamknięciu (tzw. cookies sesyjne). Inne cookies są zachowywane na Twoim urządzeniu końcowym i umożliwiają rozpoznanie Twojej przeglądarki przy kolejnym wejściu na stronę (trwałe cookies).
4. Z plików cookies korzystamy na podstawie Twojej zgody, za wyjątkiem sytuacji, gdy pliki cookies są niezbędne do prawidłowego świadczenia na Twoją rzecz usługi drogą elektroniczną.
5. Pliki cookies, które nie są niezbędne do prawidłowego świadczenia usługi drogą elektroniczną, pozostają zablokowane do czasu wyrażenia przez Ciebie zgody na korzystanie z plików cookies. Podczas Twojej pierwszej wizyty na naszej stronie wyświetlamy Ci komunikat z pytaniem o Twoją zgodę wraz z możliwością zarządzania plikami cookies, tj. zdecydowania, na które pliki cookies się godzisz, a które chcesz blokować.
6. Pamiętaj, że wyłączenie lub ograniczenie obsługi plików cookies może uniemożliwiać używanie części funkcji dostępnych na mojej stronie internetowej oraz powodować trudności w korzystaniu ze strony, jak również z wielu innych stron internetowych, które stosują cookies.
7. Pliki cookies własne wykorzystywane są do zapewnienia prawidłowego funkcjonowania poszczególnych mechanizmów strony, takich jak wysłanie wiadomości poprzez formularz kontaktowy.
8. W plikach cookies własnych przechowywana jest również informacja o zdefiniowanych przez Ciebie ustawieniach cookies dokonanych z poziomu mechanizmu do zarządzania plikami cookies.


## § 11. Postanowienia końcowe



1. Administrator danych zastrzega możliwość zmiany niniejszej polityki prywatności, szczególnie w sytuacji, gdy będą tego wymagały zastosowane rozwiązania techniczne, zmiany przepisów prawa w zakresie prywatności osób, których dane dotyczą lub inne, istotne zmiany wpływające na treść informacji przekazywanej Użytkownikom.
2. W przypadku zmiany obowiązującej polityki prywatności, wprowadzone zostaną odpowiednie modyfikacje do powyższego zapisu, które będą obowiązywały od dnia ich opublikowania na stronie Serwisu.
 
    `)


export const IndexPage = ({

}) => {


    const siteMeta = useSiteMetadata();


        

    return (
        <Layout variant="regulamin">

            <Container maxWidth="sm" sx={{
        
                position: "relative"
            }}>
                <ButtonBackToMainPage />

                {/* <Typography variant="h1">Polityka prywatności</Typography> */}


                <Markdown>{getData(siteMeta)}</Markdown>


            </Container>
        </Layout>

    );
};




export default IndexPage;

export const Head = () => {

    const { tel,
        linkgooglemap,
        address,
        title,
        description,
        siteUrl
    } = useSiteMetadata();

    return (<>
        {/* https://www.searchenginejournal.com/important-tags-seo/156440/ */}
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* <meta name="robots" content="nofollow"> */}

        {/* <link rel="canonical" href={siteUrl} /> */}

        <meta property="og:title" content={title} />

        <meta property="og:description" content={description} />

        <meta property="og:image" content={`${siteUrl}/img/map.jpg`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* <meta charset="UTF-8" /> */}


    </>


    )
}


